import '../../styles/VerifyAssessments.scss'
import { t } from "i18next";

const VerifyAssessmentsForm = ({handleChangeInputText, executeFetchUserCompletedAssessments, showErrorMessage, inputRef}) => {
    
    return (
        <>
            <div className="verify-assessments-form__container">
                <label htmlFor="basicInputTypeText">{t("email")}<span>*</span></label>
                <input
                    className="form-control"
                    id="basicInputTypeText"
                    placeholder="example@domain.com"
                    type="email" required
                    onChange={handleChangeInputText}
                    ref={inputRef}
                />

                {showErrorMessage && (
                    <div className="verify-assessments-form__error-message">
                        {t("invalid-email-message")}
                    </div>
                )}

                <button type="submit" onClick={executeFetchUserCompletedAssessments} className="verify-assessments-form__button">
                    <span>{t("verify-assessments")}</span>
                </button>

                <div className="verify-assessments-form__contact">
                    {t("contact")} <span>certification@liferay.com</span> {t("for-any-questions")}.
                </div>
            </div>
        </>
    )
}

export default VerifyAssessmentsForm;