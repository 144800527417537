import "../index.scss";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from '@clayui/tooltip';
import { getShortText, getPersonas, getTooltipPersona } from "../utils/util";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLearningPathPage } from "../services/learning-path";

const LearningPathPage = () => {
  const [learningPaths, setLearningPaths] = useState(null);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    const fetchLearningPathPage = async () => {
      const result = await getLearningPathPage();
      setLearningPaths(result.items);
    };
    fetchLearningPathPage();
  }, []);

  return (
    <div className="container-cards-page">
      {learningPaths && learningPaths.length > 0 && (
        <>
          {learningPaths.map((learningPath, index) => {
            return (
              <ClayLayout.Col key={index} className="card-learningPath-2">
                <div className="tag-learningPath">
                  <span>{t("Learning Path")}</span>
                </div>
                <a
                  href={`/l/${learningPath.id}`}
                  id="learningPath-container-link"
                >
                  <div className="learningPath-container d-flex learn-education__landing-card">
                    <div className="learningPath-content-card d-flex flex-column">
                      <h4 className="title">{learningPath.title}</h4>
                      <div className="description">
                        {getShortText(learningPath.description, 150)}
                      </div>
                      <div
                        className="d-flex information learn-education"
                        key={learningPath.id}
                      >
                        <ClayTooltipProvider>
                          <div
                            className="card-tag card-tag__persona"
                            data-tool-tip-align="top"
                            title={getTooltipPersona(learningPath.persona)}
                          >
                            <p></p>
                            <span>{getPersonas(learningPath.persona)}</span>
                          </div>
                        </ClayTooltipProvider>
                        <div
                          className={`card-tag card-tag__expertise-${learningPath.level.name.toLowerCase()}`}
                        >
                          <p>{learningPath.level.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </ClayLayout.Col>
            );
          })}
        </>
      )}
    </div>
  );
};

export default LearningPathPage;
