import "../../styles/CertificationsDetails.scss";
import { convertMinutesToDuration } from "../../utils/util";
import UseSanitizeHTML from "../Hooks/UseSanitizeHTML";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getLearningPathTitle } from "../../services/learning-path";

const CertificationsDetailsCard = ({ duration, title, expandedCards, description, toggleDescription, learningPaths, assessmentId, url }) => {
    const { t } = useTranslation();
    const [learningPathTitles, setLearningPathTitles] = useState({});

    useEffect(() => {
        const fetchLearningPathTitles = async () => {
            const LearningPathTitlesMap = {};
            for (const learningPath of learningPaths) {
                try {
                    const response = await getLearningPathTitle(learningPath.id);
                    LearningPathTitlesMap[learningPath.id] = response.title; 
                } catch (error) {
                    console.error(`Error to fetch Learning Path title ${learningPath.id}:`, error);
                    LearningPathTitlesMap[learningPath.id] = ""; 
                }
            }
            setLearningPathTitles(LearningPathTitlesMap);
        };

        fetchLearningPathTitles();
    }, [learningPaths]);

    const previewText = (textDescription) => textDescription.substring(0, 50) + "...";

    return (
        <div className="certifications-details-card__container">
            <div className="certifications-details-card__tag-duration">
                <p>{convertMinutesToDuration(duration, "hours")}</p>
            </div>
            <h2>{title}</h2>
            <div>
                <div className="certifications-details-card__description">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: UseSanitizeHTML(expandedCards ? description : previewText(description)),
                        }}
                    ></p>
                </div>
                <p
                    onClick={toggleDescription}
                    className="certifications-details-card__description-show-more-less-button"
                >
                    {expandedCards ? "Show Less" : "Show More"}
                </p>
            </div>

            <div className="certifications-details-card__buttons">
                {learningPaths.map((learningPath) => {
                    if (learningPath.r_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId > 0 &&
                        learningPath.r_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId === assessmentId) {

                        return (
                            <a key={learningPath.id} href={`/l/${learningPath.id}`} className="certifications-details-card__button-learning-path">
                                {learningPathTitles[learningPath.id] || "Loading..."}&nbsp;
                                {t("learning-path")}
                            </a>
                        );
                    }
                    return null;
                })}
                <a href={url} className="certifications-details-card__button-take">
                    <span>{t("take")} {title}</span>
                </a>
            </div>
        </div>
    );
};

export default CertificationsDetailsCard;
