import '../../styles/VerifyAssessments.scss'
import { t } from "i18next";

const VerifyAssessmentsEmptyResult = () => {
    const handleRefreshPage = () => {
        window.location.reload();
    };

    return (
        <div className='empty-verify-assessments-card__container'>
            <div className='empty-verify-assessments-card__results-content'>
                <strong className='empty-verify-assessments-card__title'>{t("no-assessments-available-for-this-user")}</strong>
                <p className='empty-verify-assessments-card__please-ensure-message'>{t("please-ensure-they-have-completed")}</p>
                <div className='empty-verify-assessments-bottom-card__container'>
                    <div className="empty-verify-assessments-bottom-card__contact">
                        {t("contact")} <span>certification@liferay.com</span> {t("for-any-questions")}
                    </div>
                    <div className='empty-verify-assessments-bottom-card__buttons'>
                        <button onClick={handleRefreshPage} className='empty-verify-assessments-bottom-card__verify-another-user-message'>
                            <span>{t("verify-another-user")}</span>
                        </button>
                        <div>
                            <a href='/education/certifications' className='empty-verify-assessments-bottom-card__link'>{t("return-to-certifications-home-page")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyAssessmentsEmptyResult;