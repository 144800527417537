import React from 'react';
import '../../styles/VerifyAssessments.scss'
import { useEffect, useState } from "react";
import VerifyAssessmentsEmptyResult from './VerifyAssessmentsEmptyResult';
import { t } from "i18next";
import { formatDate } from '../../utils/util';

const CompletedAssessmentsPage = (userCompletedAssessments) => {

    const [isCompletedAssessmentsEmpty, setIsCompletedAssessmentsEmpty] = useState(false);

    const handleRefreshPage = () => {
        window.location.reload();
    };

    useEffect(() => {
        const completedAssessmentsLength = userCompletedAssessments.userCompletedAssessments.length;
        if ( completedAssessmentsLength <= 0) {
            setIsCompletedAssessmentsEmpty(true);
        }
    }, [userCompletedAssessments.userCompletedAssessments]); 

    return (
    <>
      {userCompletedAssessments.userCompletedAssessments.length > 0 ? (
        <div className={isCompletedAssessmentsEmpty ? 'completed-assesments-card__hide-results' : 'completed-assessments-card__results'}>
            <div className='completed-assessments-card__results-contents'>
                <strong className={isCompletedAssessmentsEmpty ? 'hide' : 'completed-assessments-card__results-title'}>{t("completed-assessments-for")} {userCompletedAssessments.userCompletedAssessments[0].email}</strong>
                <div className={isCompletedAssessmentsEmpty ? 'hide' : 'completed-assessments-card__name-date'}>
                    <span className='completed-assesments-card__name-date-name'>{t("assessment-name")}</span>
                    <span>{t("passed-on")}</span>
                </div>
                <div className='completed-assesments-card__user-infos-container'>
                    <ul>
                        {userCompletedAssessments.userCompletedAssessments.map((item, index) => (
                            <li key={index}>
                                <div className="completed-assessments-card__user-infos">
                                    {item.date && item.testName && (
                                        <>
                                            <span className='completed-assessments-card__user-infos-testName'>{item.testName}</span>
                                            <span className='completed-assessments-card__user-infos-date'>{formatDate(item.date)}</span>
                                        </>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={isCompletedAssessmentsEmpty ? 'hide' : 'completed-assessments-bottom-card__container'}>
                <div className="completed-assessments-bottom-card__contact">
                    {t("contact")} <span>certification@liferay.com</span> {t("for-any-questions")}
                </div>
                <div className='completed-assessments-bottom-card__butons'>
                    <button onClick={handleRefreshPage}>
                        <span>{t("verify-another-user")}</span>
                    </button>
                    <div>
                        <a href='/education/certifications' className='completed-assessments-bottom-card__link'>{t("return-to-certifications-home-page")}</a>
                    </div>
                </div>
            </div>
        </div>
      ) : (
        <VerifyAssessmentsEmptyResult />
      )}
    </>
  );
};

export default CompletedAssessmentsPage;