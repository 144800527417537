import '../../styles/VerifyAssessments.scss'
import { useState, useRef } from "react";
import { getExamResultsByUserEmail } from '../../services/exam-result';
import VerifyAssessmentsForm from './VerifyAssessmentsForm';
import CompletedAssessmentsPage from './CompletedAssessmentsPage';

const VerifyAssessmentsView = () => {

    const [changeToCompletedAssessmentsPage, setChangeToCompletedAssessmentsPage] = useState(false);
    const [email, setEmail] = useState('');
    const [userCompletedAssessments, setUserCompletedAssessments] = useState([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const inputRef = useRef(null);

    const handleChangeInputText = (event) => {
        setEmail(event.target.value);
    };

    const fetchUserCompletedAssessmentsByUserEmail = async () => {
        const data = await getExamResultsByUserEmail(email);

        setUserCompletedAssessments(data);
        if(isValidEmail(email)) {
            setChangeToCompletedAssessmentsPage(true);
        }  
    };

    const executeFetchUserCompletedAssessments = () => {
        let current_email = inputRef.current?.value;

        setEmail(current_email);
        fetchUserCompletedAssessmentsByUserEmail(current_email);
        if(!isValidEmail(current_email)) {
            setShowErrorMessage(true)
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    };

    return (
        <>
            {!changeToCompletedAssessmentsPage && (
                <VerifyAssessmentsForm 
                    handleChangeInputText={handleChangeInputText}
                    executeFetchUserCompletedAssessments={executeFetchUserCompletedAssessments}
                    showErrorMessage={showErrorMessage}
                    inputRef={inputRef}
                />
            )}

            {changeToCompletedAssessmentsPage && isValidEmail && (
                <CompletedAssessmentsPage 
                    userCompletedAssessments={userCompletedAssessments}
                />
            )}

        </>
    )
}

export default VerifyAssessmentsView;