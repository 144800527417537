import { getCurrentSiteId } from "../utils/util";
const Liferay = window.Liferay;

export const getLearningPathPage = async () => {
  const response = await Liferay.Util.fetch(
    `/o/c/learningpaths/scopes/${getCurrentSiteId()}?fields=id,description,level,persona,title,`
  );
  const data = await response.json();
  return data;
};

export const getLearningPathTitle = async (id) => {
  const response = await Liferay.Util.fetch(
    `/o/c/learningpaths/${id}?fields=title,`
  );
  const data = await response.json();
  return data;
};