import React from "react";
import { getSignInPath, isSignedIn } from "../../utils/util";
import { t } from "i18next";

function QuizHome({
  isKnoledgeCheck,
  moduleInfo,
  questions,
  setFirstQuestion,
  handleRegisterUserAndUpdateEnrollment
}) {

  return (
    <div className="home-quiz">
      <div className="text-home-quiz">
        <p>
          {!isKnoledgeCheck
            ? `${t("quiz-home-message")}`
            : `${t("quiz-home-message-ck")}`}
        </p>
      </div>
      {!isSignedIn() ? (
        <div className="button-bottom-home button-bottom-home-unassigned">
          <div>
            <a href={getSignInPath()}>{t("sign-in")}</a>{" "}
            {t("quiz-sign-in-text")}
          </div>
          <button onClick={() => setFirstQuestion(true)}>
            {t("start-test")}
          </button>
        </div>
      ) : (
        <div className="button-bottom-home">
          <button
            onClick={() => handleRegisterUserAndUpdateEnrollment()}
          >
            {t("start-test")}
          </button>
        </div>
      )}
    </div>
  );
}

export default QuizHome;
