/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../../styles/CertificationsDetails.scss";
import { getPersonas, getTooltipPersona } from "../../utils/util";
import { useTranslation } from "react-i18next";
import { getAssessments, getLearningPathsIdFromAssessments, getCurrentCertification } from "../../services/certification";
import { ClayTooltipProvider } from '@clayui/tooltip';
import CertificationsDetailsCard from "./CertificationsDetailsCard";

const CertificationsDetails = () => {
    const [assessments, setAssessments] = useState(null);
    const [certifications, setCertifications] = useState(null);
    const [expandedCards, setExpandedCards] = useState({});
    const [learningPaths, setLearningPaths] = useState([]);

    const {
        t,
      } = useTranslation();

    const toggleDescription = (index) => {
        setExpandedCards((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    useEffect(() => {
        const fetchAssessments = async () => {
            const response = await getAssessments();

            setAssessments(response);
        };

        const fetchCertifications = async () => {
            const response = await getCurrentCertification();

            setCertifications(response);
        };

        const fetchLearningPathsId = async () => {
            const response = await getLearningPathsIdFromAssessments();

            setLearningPaths(response);
        };

        fetchAssessments();

        fetchCertifications();

        fetchLearningPathsId();

    }, []);

    return (
        <>
            <div className="certification-details__banner">
                <div className="certification-details__banner-contents">
                    <div className="certification-details__banner-tag-certification">
                        <p>{t("certification")}</p>
                    </div>

                    <h1>{certifications?.name}</h1>

                    <div className="certification-details__banner-tags">
                        <div className="certification-details__banner-info-tag d-flex mt-3">
                            {certifications &&
                                <ClayTooltipProvider>
                                    <div className="certification-details__banner-tag-persona" data-tool-tip-align="top" title={getTooltipPersona(certifications.persona)}>
                                        <p></p>
                                        {getPersonas(certifications.persona)}
                                    </div>
                                </ClayTooltipProvider>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="certifications-details__main">
                <div className="certifications-details__main-title">
                    <h1>{t("required-assessments")}</h1>
                    <p>{t("description-assessments-certifications")}</p>
                </div>

                {assessments && assessments.length > 0 && learningPaths && learningPaths.length > 0 && (
                    <>
                        {assessments.map((assessment, index) => (
                    
                            <li key={assessment.id}>

                                <CertificationsDetailsCard
                                    duration={assessment.durationMinutes}
                                    title={assessment.title}
                                    expandedCards={expandedCards[index]}
                                    description={assessment.description}
                                    toggleDescription={() => toggleDescription(index)}
                                    learningPaths={learningPaths}
                                    assessmentId={assessment.id}
                                    url={assessment.uRL}
                                />

                            </li>
                        ))}
                    </>
                )}
            </div>
        </>
    );
}

export default CertificationsDetails;